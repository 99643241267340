
<script>
import * as echarts from 'echarts'
import API from "../../plugins/axiosInstance"

export default {
  data () {
    return {
      title: '当前灯箱数',
      percent: 0,
      deviceInfo:{
        run:0,
        deviceLen:0
      }
    }
  },
  methods: {
		initEcharts(myCharts) {
      let that = this
      that.percent = Math.floor((parseInt(that.deviceInfo.run,10) / parseInt(that.deviceInfo.deviceLen,10)) * 100)
      console.log(that.percent)
      this.mycharts = echarts.init(this.$refs[myCharts]);
      this.mycharts.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        series: [
          {
            name: "今日在线率",
            type: "pie",
            radius: ["65%", "80%"],
            avoidLabelOverlap: false,
            smooth: true,
            label: {
                normal: {
                    show: true,
                    position: 'center',
                    color:'#fff',
                    formatter: '{total|' + this.percent +'}%',
                    rich: {
                        total:{
                            fontSize: 16,
                            fontFamily : "微软雅黑",
                            color:'#fff'
                        },
                        active: {
                            fontFamily : "微软雅黑",
                            fontSize: 16,
                            color:'#fff',
                            lineHeight:30,
                        },
                    }
                },
                emphasis: {//中间文字显示
                    show: true,
                }
            },
            lableLine: {
              normal: {
                  show: false
              },
              emphasis: {
                  show: true
              },
              tooltip: {
                  show: false
              }
            },
            data: [
              { value: this.deviceInfo.run,name:'开启灯箱数'},
              { value: this.deviceInfo.deviceLen - this.deviceInfo.run,name:'离线灯箱数'}
            ],
          }
        ]
      });
    },
    
    getLightBoxData(){
      let token = "token "+localStorage.getItem("token")
      const that = this
      API({
        url:'/api/v1/front-end/GetDeviceDetails',
        method:'POST',
        data:{
            "deviceList": "all"
        },
        headers:{
          'Content-type': 'application/x-www-form-urlencoded',
          "Authorization": token,
        },
      }).then((res)=>{
        that.deviceInfo.deviceLen = res.data.length
        that.deviceInfo.run = res.data.filter(item => item.status == 1).length
      }).catch(() => {
        
      });
    }
  },
  mounted(){
      const that = this
      that.getLightBoxData()
      // setInterval(() => {
      //   that.getLightBoxData()
      // },5000)
      setTimeout(() => {that.initEcharts("lightbox")}, 1000)
  }
}
</script>

<template>
  <div class="container-box">
		<div class="title">{{title}}</div>
		<div class="row">
			<div class="col-4">
				<div class="coltitle">灯箱总数</div>
				<div class="colcontent coltext">{{deviceInfo.deviceLen}}</div>
			</div>
			<div class="col-4">
				<div class="coltitle">开启灯箱数</div>
				<div class="colcontent coltext">{{deviceInfo.run}}</div>
			</div>
			<div class="col-4">
				<div class="coltitle">今日在线率</div>
				<div class="colcontent">
					<div ref="lightbox" class="chartBox_d"></div>
				</div>
			</div>
		</div>
  </div>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-box {
	padding: 0 15px;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.title {
	font-size: 16px;
	color: #fff;
	padding: 10px 0;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
	margin-bottom: 10px;
}
.coltitle {
	color: #7e7d83;
	font-size: 14px;
  text-align: center;
}
.colcontent {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 42px;
	font-weight: 700;
  padding-top: 50px;
}
.coltext{
    height: 165px;
  }
.chartBox_d {
  width: 124px;
  height: 124px;
}
@media screen and (max-width:1600px) {
  .colcontent {
    padding-top: 15px;
  }
  .coltext{
    height: 125px;
  }
}
</style>
