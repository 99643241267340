<template>
  <div class="container-box">
		<div class="title">
      <div>{{title}}</div>
      <div>
        <ul>
          <li><span class="status-1"></span>温度</li>
          <li><span class="status-2"></span>湿度</li>
        </ul>
      </div>
    </div>
		<div class="row">
				<div id="temperraturea" class="chartBox_d"></div>
		</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: '灯箱平均温湿度'      
    }
  },
	mounted(){
    let that = this
    // that.drawLine()
    setTimeout(() => {that.drawLine()}, 500)
  },
  methods: {
   //接口请求获取的数据
    drawLine(){
    // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('temperraturea'))
    // 绘制图表
      myChart.setOption({
      // title: {
      //   text: '折线图堆叠'
      // },
        tooltip: {
          trigger: 'axis',
					formatter: function(params) {
						console.log(params)
						var result = ''
						var dotHtml = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#ec5527"></span>'
						var dotHtml2 = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#3b9ac6"></span>'
						result += params[0].axisValue + "</br>" + dotHtml +'温度：' + params[0].data+ "</br>" + dotHtml2 +'湿度：' + params[1].data
						return result
					}
        },
      // legend: {
      //     data: ['邮件营销', '联盟广告', '视频广告' ]
      // },
        grid: {
            left: '0%',
            right: '3%',
            bottom: '15%',
            top:'20%',
            containLabel: true
        },
      // toolbox: {
      //     feature: {
      //         saveAsImage: {}
      //     }
      // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            show: true,
            textStyle: {
                color: '#fff'
            }
          }
        },
        yAxis: {
          // 去掉轴多出来的-
          axisTick: {
            show: false
          },
          type: 'value',
          splitLine: {
            show: true
          },
          show: true,
          fontSize: 10,
          axisLabel: {
            show: true,
            textStyle: {
                color: '#fff'
            }
          }
        },
        series: [
          {
            name: '温度',
						type: 'line',
            smooth: true,
						//stack: '总量',
						data: [120, 132, 101, 134, 90, 230, 210],
						lineStyle: {
							color:'#ec5527'
						},
						itemStyle:{//折线拐点标志的样式
							borderColor:"#ec5527",//拐点的边框颜色
							borderWidth:0,
							opacity:0
						}
          },
          {
						name: '湿度',
						type: 'line',
            smooth: true,
						//stack: '总量',
						data: [220, 182, 191, 234, 290, 330, 310],
						lineStyle: {
							color:'#3b9ac6'
						},
						itemStyle:{//折线拐点标志的样式
							borderColor:"#3b9ac6",//拐点的边框颜色
							borderWidth:1,
							opacity:0
						}
          }
        ]
      });
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-box {
	padding: 0 15px;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.title {
	font-size: 16px;
	color: #fff;
	padding: 10px 0 0 0;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
	margin-bottom: 0;
  display: flex;
  flex-direction: row;
}
.title>div>ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}
.title>div>ul>li {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
}
.title>div>ul>li span {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 5px;
}
.status-1 {
  background-color: #ec5527;
}
.status-2 {
  background-color: #3b9ac6;
}
.status-3 {
  background-color: #f3df4c;
}
.coltitle {
	color: #7e7d83;
	font-size: 14px;
}
.colcontent {
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 42px;
	font-weight: 700;
}
.chartBox_d {
  width: 100%;
  height: 250px;
}
  
@media screen and (max-width:1600px) {
  .chartBox_d {
    height: 225px;
    margin-top: -15px;
  }
}
@media screen and (max-width:1440px) {
  .title {
    font-size: 14px;
  }
  .title>div>ul>li {
    margin: 0 5px;
  }
}

@media screen and (max-width:820px) {
  .title {
    font-size: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  .title>div>ul>li {
    margin: 0 5px;
  }
  .chartBox_d {
    width: 100%;
    height: 200px;
  }
}
</style>
