<template>
  <div class="container-app">
    <div class="systemLogo">
      <div class="title">{{title}}</div>
      <div class="time">{{currentTime}}</div>
      <div class="switch">
        <div class="link"><router-link to="/home">返回首页</router-link></div>
        <div class="link"><router-link to="/MapBoard">地图看板</router-link></div>
      </div>
    </div>
    <div class="main row">
      <div class="col-9 cards left">
				<div class="devicelistTab">
					<ul>
						<li @click="switchDeviceNo" data-no="1">
              <div :class="{'cur':deviceNo == 1}">
                <span :class="{'beused':device1Flag == true}"></span>设备清单1
              </div>
            </li>
						<li @click="switchDeviceNo" data-no="2">
              <div :class="{'cur':deviceNo == 2}">
                <span :class="{'beused':device2Flag == true}"></span>设备清单2
              </div>
            </li>
						<li @click="switchDeviceNo" data-no="3">
              <div :class="{'cur':deviceNo == 3}">
                <span :class="{'beused':device3Flag == true}"></span>设备清单3
              </div>
            </li>
						<li @click="switchDeviceNo" data-no="4">
              <div :class="{'cur':deviceNo == 4}">
                <span :class="{'beused':device4Flag == true}"></span>设备清单4
              </div>
            </li>
					</ul>
				</div>
        <div v-if="deviceNo == 1" class="device-box">
          <device-list-one />
        </div>
        <div v-if="deviceNo == 2" class="device-box">
          <device-list-two />
        </div>
        <div v-if="deviceNo == 3" class="device-box">
          <device-list-three />
        </div>
        <div v-if="deviceNo == 4" class="device-box">
          <device-list-four />
        </div>
      </div>
			<div class="col-3 cards right">
        <div class="chartscards">
          <div class="light-card">
            <device-utilization-rate />
          </div>
          <div class="light-card">
            <state-distribution />
          </div>
          <div class="light-card nomargin">
            <poor-radar-image />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceListOne from '../../views/devicelist1/index.vue'
import DeviceListTwo from '../../views/devicelist2/index.vue'
import DeviceListThree from '../../views/devicelist3/index.vue'
import DeviceListFour from '../../views/devicelist4/index.vue'
import DeviceUtilizationRate from '../../views/deviceutilizationrate/index.vue'
import StateDistribution from '../../views/statedistribution/index.vue'
import PoorRadarImage from '../../views/poorradarimage/index.vue'
import API from "../../plugins/axiosInstance"
export default {
  data () {
    return {
      title: '设备运行状态',
      deviceNo:1,
      deviceLen:0,
      device1:[],
      device2:[],
      device3:[],
      device4:[],
      currentTime:"",
      device1Flag:false,
      device2Flag:false,
      device3Flag:false,
      device4Flag:false
    }
  },
  components: {
    DeviceListOne,
		DeviceListTwo,
		DeviceListThree,
		DeviceListFour,
		DeviceUtilizationRate,
		StateDistribution,
		PoorRadarImage
  },
  methods:{
    switchDeviceNo(e){
      this.deviceNo = e.currentTarget.dataset.no
    },
    getToken(){
      console.log(localStorage.getItem("token"))
      if(!localStorage.getItem("token") || localStorage.getItem("token") === '' || localStorage.getItem("token") === null){
        this.$router.push('/login')
      }
    },
    getCurrentTime(){
      const now = new Date()
      this.currentTime = now.toLocaleString().replace(/\//g,"-")
    },
    checkVoltage1(){
      const that = this
      let voltageA = 0;
      let voltageB = 0;
      let voltageC = 0;
      voltageA = that.device1.filter(item => item.voltagePhaseA >= 200).length;
      voltageB = that.device1.filter(item => item.voltagePhaseB >= 200).length;
      voltageC = that.device1.filter(item => item.voltagePhaseC >= 200).length;
      if(voltageA !=0 || voltageB != 0 || voltageC != 0){
        that.device1Flag = true
      }
    },
    checkVoltage2(){
      const that = this
      let voltageA = 0;
      let voltageB = 0;
      let voltageC = 0;
      voltageA = that.device2.filter(item => item.voltagePhaseA >= 200).length;
      voltageB = that.device2.filter(item => item.voltagePhaseB >= 200).length;
      voltageC = that.device2.filter(item => item.voltagePhaseC >= 200).length;
      if(voltageA !=0 || voltageB != 0 || voltageC != 0){
        that.device2Flag = true
      }
    },
    checkVoltage3(){
      const that = this
      let voltageA = 0;
      let voltageB = 0;
      let voltageC = 0;
      voltageA = that.device3.filter(item => item.voltagePhaseA >= 200).length;
      voltageB = that.device3.filter(item => item.voltagePhaseB >= 200).length;
      voltageC = that.device3.filter(item => item.voltagePhaseC >= 200).length;
      if(voltageA !=0 || voltageB != 0 || voltageC != 0){
        that.device3Flag = true
      }
    },
    checkVoltage4(){
      const that = this
      let voltageA = 0;
      let voltageB = 0;
      let voltageC = 0;
      voltageA = that.device4.filter(item => item.voltagePhaseA >= 200).length;
      voltageB = that.device4.filter(item => item.voltagePhaseB >= 200).length;
      voltageC = that.device4.filter(item => item.voltagePhaseC >= 200).length;
      if(voltageA !=0 || voltageB != 0 || voltageC != 0){
        that.device4Flag = true
      }
    },
    getLightBoxData(){
      let token = "token "+localStorage.getItem("token")
      const that = this
      API({
        url:'/api/v1/front-end/GetDeviceDetails',
        method:'POST',
        data:{
            "deviceList": "all"
        },
        headers:{
          'Content-type': 'application/x-www-form-urlencoded',
          "Authorization": token,
        },
      }).then((res)=>{
        //console.log(res)
        that.device1 = res.data.filter(item => item.page == 1)
        that.device2 = res.data.filter(item => item.page == 2)
        that.device3 = res.data.filter(item => item.page == 3)
        that.device4 = res.data.filter(item => item.page == 4)
        that.deviceLen = res.data.length
        //that.deviceInfo.baselen = that.listData.length
        //that.deviceInfo.outline = that.listData.filter(item => item.status == 0).length
        //that.deviceInfo.run = that.listData.filter(item => item.status == 1).length
        //that.deviceInfo.check = that.listData.filter(item => item.status == 2).length
        //that.deviceInfo.unable = that.listData.filter(item => item.status == 3).length
      });
    }
  },
  mounted(){
    const that = this
    that.getToken()
    setInterval(() => {
      that.getCurrentTime()
      if(new Date() > new Date(localStorage.getItem("expiretime"))){
        localStorage.setItem("token", '')
        this.$router.push('/login')
      }
      //this.getLightBoxData()
    },1000)
    //that.getLightBoxData()
    that.getLightBoxData()
    setInterval(() => {
      that.checkVoltage1()
      that.checkVoltage2()
      that.checkVoltage3()
      that.checkVoltage4()
    },5000)
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.container-app {
  min-width: 1900px;
  width: auto;
}
.systemLogo {
  width:100%;
  height:70px;
  display:flex;
  align-items:center;
  justify-content: space-between;
  background:rgba(3, 3, 34, 0.8);
  margin-bottom:15px;
}
.systemLogo>div {
  padding: 0 60px;
  color:#fff;
}
.title {
  height:70px;
  line-height:70px;
  font-size: 20px;
  background:rgba(3, 3, 34, 0.8) left center no-repeat;
  background-size: 60px 60px;
}
/*url(../../../assets/logo.png)  */
.time {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 20px;
}
.switch {
  display:flex;
}
.link {
  display:block;
  padding:5px 10px;
  line-height: 30px;
  border:1px solid #fff;
  margin: 0 5px;
  border-radius:5px;
}
a {
  display:block;
  width:4em;
  line-height: 30px;
  color:#fff;
  text-decoration:none;
}
.main {
  width: calc(100% - 10px);
  margin: 0 auto;
}
.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:baseline;
  height: 975px;
}
.light-card {
  width: calc(100% - 10px);
  background:rgba(3, 3, 34, 0.8);
  border-radius: 5px;
  height: 312px;
  margin: 0 0 24px 0;
}
.device-box {
	width: 100%;
  height: 928px; /*calc(100vh - 148px);*/
}
.map-card {
  width: 100%;
  background:rgba(3, 3, 34, 0.8);
  border-radius: 5px;
  height: calc(60vh - 10px);
  margin: 0 0 30px 0;
}

.devicelistTab {
	display: flex;
	width: 100%;
  height: 45px;
	align-items: center;
	justify-content: space-between;
  margin-bottom: 10px;
}
.devicelistTab ul {
	display: flex;
	width: 100%;
  height: 45px;
	align-items: center;
}
.devicelistTab ul li {
	color: #fff;
	font-size: 16px;
	cursor: pointer;
  width: 120px;
  height: 40px;
}
.devicelistTab ul li span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #707070;
  margin-right: 5px;
}
.devicelistTab ul li span.beused {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #8ef474;
  margin-right: 5px;
}
.devicelistTab ul li>div {
  padding:0 10px;
  height: 38px;
  line-height: 38px;
}
.devicelistTab ul li>div.cur {
  width: 100%;
  height: 38px;
  line-height: 38px;
  border-radius: 10px;
  color: #92c1f7;
  border: #92c1f7 solid 1px;
}
.chartscards {
  width: 100%;
}
@media screen and (max-width:1600px) {
  .container-app {
    width: 1600px;
    height: inherit;
    background-size:cover
  }
  /*.devicelist-box {
    padding-bottom: 15px;
  }*/
  .light-card {
    height: 245px;
  }
  .device-box {
    height:inherit;
    padding-bottom: 30px;
  }
  .cards {
    height:inherit
  }
}

@media screen and (max-width:1440px) {
  .container-app {
    min-width: inherit;
    width: 100%;
    height: inherit;
    background-size:cover
  }

}
@media screen and (max-width:1200px) {
  
  .systemLogo>div {
    padding: 0 15px!important;
  }
}

@media screen and (max-width:1024px) {
  .main {
    position: relative;
    margin: 0;
    padding: 0;
  }
  .left {
    position: absolute;
    left:0;
    top: 269px;
    width: 100%!important;
    z-index: 10;
  }
  .right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 245px;
    z-index: 10;
    overflow: hidden;
  }
  
  .chartscards {
    display: flex;
    flex-direction: row;
    width: calc(100% + 24px);
  }
  .right>div>div {
    width: calc(100%/3 - 24px);
    margin: 0 12px;
  }
}

@media screen and (max-width:414px) {
  .systemLogo {
    display: block;
    height: inherit;
    padding-bottom: 12px;
  }
  .systemLogo>div {
    padding: 12px 15px!important;
  }
  .main {
    width: 100%;
  }
  .left {
    position: absolute;
    left:0;
    top: 807px;
    width: 100%!important;
    z-index: 10;
  }
  
  .right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: inherit;
    z-index: 10;
    overflow: hidden;
  }
  .right>div>div {
    width:calc(100% - 24px);
    margin: 0 12px 24px 12px;
  }
  .chartscards {
    display: block;
    margin: inherit;
  }
  .devicelistTab {
    height: inherit;
  }
  .devicelistTab ul {
    display: block;
    height: inherit;
  }
  .devicelistTab ul li {
    display: inline-block;
    width: 110px;
    margin: 0;
  }
  .devicelistTab ul li>div{
    padding: 0 10px;
    font-size: 14px;
  }
}
</style>
