
<template>
  <div class="container-box">
		<div class="title">
      <div>{{title}}</div>
    </div>
		<div class="row">
				<div id="poorradarimage" class="chartBox_d"></div>
		</div>
  </div>
</template>

<script>
// import * as echarts from 'echarts'
// import echarts from 'echarts'
export default {
  name: 'top5',
  data () {
    return {
      title: '不良Top5雷达图'      
    }
  },
	mounted(){
    let that = this
    that.drawLine()
    // setTimeout(() => {this.drawLine()}, 1000)
  },
  methods: {
   //接口请求获取的数据
    drawLine(){
    // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('poorradarimage'))
    // 绘制图表
      
      let datas = [
        { Name: '运行', TotalScore: 80, Score: 80, AvgScore: 72 },
        { Name: '离线', TotalScore: 80, Score: 50, AvgScore: 63 },
        { Name: '故障', TotalScore: 80, Score: 50, AvgScore: 58 },
        { Name: '报警', TotalScore: 80, Score: 30, AvgScore: 68 },
        { Name: '停止', TotalScore: 80, Score: 50, AvgScore: 65 }
      ];
      let colorList = ['#36A87A', '#3f76f2'];
      let aveList = datas.map((n) => { return n.AvgScore; });
      let uList = datas.map((n) => { return n.Score; });
      let nameList = [];
      datas.forEach((item) => {
        nameList.push({
          name: item.Name,
          max: 80,
          AvgScore: item.AvgScore,
          Score: item.Score
        });
      });
      myChart.setOption({
        grid: {
            left: '0%',
            right: '3%',
            bottom: '15%',
            top:'20%',
            containLabel: true
        },
        legend: {
          data: ['月度', '实时'],
          left: 'center',
          top: 'bottom',
          itemGap: 30,
          textStyle: {
            // 图例文字的样式
            color:'#b1b5ce',
            fontSize: 12
          }
        },
        radar: {
          center: ['50%', '55%'], // 图表位置
          radius: '65%', // 图表大小
          // 设置雷达图中间射线的颜色
          axisLine: {
            lineStyle: {
              color: '#999',
              fontSize: 30
            }
          },
          indicator: nameList,
          // 雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
          splitArea: {
            areaStyle: {
              color: '#40424f' // 图表背景的颜色
            }
          },
          name: {
            lineHeight: 12,
            formatter: (labelName) => {
              //const { AvgScore, Score } = raw;
              return (
                labelName// + '\n' + `{score|${Score}}` + '/' + `{avg|${AvgScore}}`
              );
            },
            rich: {
              score: {
                color: colorList[0],
                fontSize: 16
              },
              avg: {
                color: colorList[1],
                fontSize: 16
              }
            }
          }
        },
        polar: {
          radius: '50%'
        },
        angleAxis: {
          zlevel: 0,
          min: 0,
          max: 360,
          clockwise: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        radiusAxis: {
          zlevel: 0,
          min: 0,
          max: 100,
          interval: 20,
          axisLabel: {
            textStyle: {
              color: '#AFAFAF',
              fontSize: 10
            }
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          splitArea: {
            show: false
          }
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: uList,
                name: '月度',
                // 设置区域边框和区域的颜色
                itemStyle: {
                  color: colorList[0]
                },
                label: {
                  show: false,
                  fontSize: 12,
                  position: 'right',
                  color: colorList[0],
                  formatter: function (params) {
                    return params.value;
                  }
                },
                areaStyle: {
                  color: colorList[0],
                  opacity: 0.5
                }
              },
              {
                value: aveList,
                name: '实时',
                // 设置区域边框和区域的颜色
                itemStyle: {
                  color: colorList[1]
                },
                label: {
                  show: false,
                  fontSize: 12,
                  position: 'left',
                  color: colorList[1],
                  formatter: function (params) {
                    return params.value;
                  }
                },
                areaStyle: {
                  color: colorList[1],
                  opacity: 0.5
                }
              }
            ]
          }
        ]
      });
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-box {
	padding: 0 15px;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.title {
	font-size: 16px;
	color: #fff;
	padding: 10px 0 0 0;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
	margin-bottom: 0;
  display: flex;
  flex-direction: row;
}
.title>div>ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
}
.title>div>ul>li {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
}
.title>div>ul>li span {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 5px;
}
.status-1 {
  background-color: #2e6aa0;
}
.status-2 {
  background-color: #37c59f;
}
.status-3 {
  background-color: #f3df4c;
}
.coltitle {
	color: #7e7d83;
	font-size: 14px;
}
.colcontent {
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 42px;
	font-weight: 700;
}
.chartBox_d {
  width: 100%;
  height: 250px;
}
@media screen and (max-width:1600px) {
  .chartBox_d {
    height: 225px;
    overflow: hidden;
    margin-top: -25px;
  }
}
</style>
